'use client'
import '../index.css'
import styled from '@emotion/styled'
import { theme } from '../../theme'
import { TopMenu } from './_components/TopMenu'
import { Footer } from './_components/Footer'
import { ReactNode } from 'react'
import Link from 'next/link'

export const Container = ({ children }: { children: ReactNode }) => {
  const currentYear = () => {
    const now = new Date()

    return now.getFullYear()
  }

  return (
    <>
      <MainContainer>
        <TopMenu />
        {children}
      </MainContainer>
      <Footer light style={{ width: '100%', justifyContent: 'center' }}>
        © {currentYear()} Booet |{' '}
        <Link
          href={'mailto:info@booet.se'}
          target={'_blank'}
          style={{ color: theme.onBackgroundLight, paddingLeft: '0.25rem' }}
        >
          info@booet.se
        </Link>
      </Footer>
    </>
  )
}

const MainContainer = styled.div`
  min-height: calc(100vh - 5rem);
`
