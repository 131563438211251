import styled from '@emotion/styled'
import { ResponsivePadding } from './ResponsivePadding'

export const Footer = styled.div<{ light?: boolean }>`
  ${ResponsivePadding};

  padding-top: 5em;
  padding-bottom: 4rem;

  border-top: 1px solid ${({ theme }) => theme.onBackgroundLightest};

  > div {
    display: flex;
    flex-direction: column;

    @media (max-width: 800px) {
      min-width: 100%;
    }
  }

  display: flex;
  flex-direction: row;

  color: ${({ theme }) => theme.onBackgroundLight};

  > div:nth-of-type(2) {
    margin-left: 10rem;
  }

  h5 {
    display: block;
    font-size: 1.1rem;
    margin-bottom: 0.75rem;
    color: #5a5a5a;
  }

  span {
    display: block;
    font-size: 1rem;
    color: ${({ theme }) => theme.onBackgroundLight};

    line-height: 1.5rem;
  }

  a {
    display: block;
    font-size: 1rem;
    color: ${({ theme }) => theme.onBackgroundLight};
  }

  a + a {
    margin-top: 0.45rem;
  }
`
