import { css } from '@emotion/react'

export const ResponsivePadding = css`
  padding-left: 15vw;
  padding-right: 15vw;

  @media (max-width: 1500px) {
    padding-left: 10vw;
    padding-right: 10vw;
  }

  @media (max-width: 1300px) {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  @media (max-width: 1100px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
`
