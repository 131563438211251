import styled from '@emotion/styled'

export const Hamburger = ({ open }: { open: boolean }) => {
  return (
    <HamburgerContainer open={open}>
      <span />
      <span />
      <span />
      <span />
    </HamburgerContainer>
  )
}

const HamburgerContainer = styled.div<{ open: boolean }>`
  width: 1.25rem;

  aspect-ratio: 3.5/2;

  position: relative;

  margin: 0 auto;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;

  span {
    display: block;
    position: absolute;
    height: 1.5px;

    width: 100%;
    background: ${({ theme }) => theme.onBackground};
    opacity: 1;
    left: 0;
    transform-origin: center;
    transition:
      transform 0.25s ease-in-out,
      opacity 0.25s ease-in-out,
      width 0.25s ease-in-out,
      top 0.25s ease-in-out;
  }

  span:nth-child(1) {
    top: ${({ open }) => (open ? '50%' : '0%')};
    opacity: ${({ open }) => (open ? 0 : 1)};
  }

  span:nth-child(2) {
    top: 50%;
    transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
  }

  span:nth-child(3) {
    top: 50%;
    transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
  }

  span:nth-child(4) {
    top: ${({ open }) => (open ? '50%' : '100%')};
    opacity: ${({ open }) => (open ? 0 : 1)};
  }
`
