'use client'
import styled from '@emotion/styled'
import { useEffect, useState } from 'react'
import { useMediaPredicate } from 'react-media-hook'
import { useScrollTop } from '@common/hooks/use-scroll-top'
import Link from 'next/link'
import { transientOptions } from '@common/utils/transient-styles'
import { Hamburger } from '@common/components/Hamburger'

export const TopMenu = () => {
  const isMobile = useMediaPredicate('(max-width: 800px)')
  const scrollTop = useScrollTop()
  const [showMobileMenu, setShowMobileMenu] = useState(false)

  useEffect(() => {
    if (!showMobileMenu) {
      document.body.style.overflow = 'visible'
    } else {
      document.body.style.overflow = 'hidden'
    }
  }, [showMobileMenu])

  useEffect(() => {
    if (!isMobile) {
      setShowMobileMenu(false)
    }
  }, [isMobile])

  const handleToggleMobileMenu = () => {
    if (showMobileMenu) {
      setShowMobileMenu(false)
    } else {
      setShowMobileMenu(true)
    }
  }

  const links = [
    {
      to: '/priser',
      text: 'Priser',
    },
    {
      to: '/blogg',
      text: 'Blogg',
    },
    {
      to: '/kontakt',
      text: 'Kontakt',
    },
    {
      to: '/in',
      text: 'Logga in',
    },
  ]

  return (
    <TopMenuContainer justifyContent="space-between" border={scrollTop > 50}>
      <TopMenuTitle href={'/'} $visible={showMobileMenu}>
        Booet
      </TopMenuTitle>
      {!showMobileMenu && (
        <ButtonSection>
          {links.map(({ to, text }) => (
            <MenuButton key={text + to} href={to}>
              {text}
            </MenuButton>
          ))}
        </ButtonSection>
      )}
      {isMobile && (
        <>
          <MobileMenu $visible={showMobileMenu}>
            <MobileContent>
              {links.map(({ to, text }, index) => (
                <MobileItem
                  key={text + to}
                  href={to}
                  onClick={handleToggleMobileMenu}
                  index={index}
                  visible={showMobileMenu}
                >
                  {text}
                </MobileItem>
              ))}
            </MobileContent>
          </MobileMenu>
          <MobileMenuButton
            onTouchEnd={(e) => {
              e.preventDefault()
              handleToggleMobileMenu()
            }}
            onClick={handleToggleMobileMenu}
          >
            <Hamburger open={showMobileMenu} />
          </MobileMenuButton>
        </>
      )}
    </TopMenuContainer>
  )
}

const TopMenuTitle = styled(Link, transientOptions)<{ $visible: boolean }>`
  z-index: 12;
  font-size: 1.5rem;
  color: ${({ theme }) => theme.primary};
  font-weight: 800;

  vertical-align: middle;

  @media (max-width: 1100px) {
    padding: 0.5rem 0.75rem 0.75rem 1.35rem;
    transition: opacity 0.15s ease-in-out;

    opacity: ${({ $visible }) => (!$visible ? 1 : 0)};
  }
`

const MobileMenuButton = styled.button`
  border: none;
  -webkit-appearance: none;
  background-color: transparent;

  padding: 1.35rem;
  margin: 0;

  z-index: 12;

  color: ${({ theme }) => theme.onBackground};
`

const TopMenuContainer = styled.div<{ justifyContent: string; border: boolean }>`
  position: fixed;
  top: 0;
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: center;

  background-color: ${({ theme }) => theme.background};

  width: 100%;
  z-index: 10;

  padding: 1.5rem 10vw;

  transition: border-bottom-color 0.25s ease-in-out;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom-color: ${({ theme, border }) => (border ? theme.onBackgroundLightest : 'transparent')};

  @media (max-width: 1500px) {
    padding-left: 10vw;
    padding-right: 10vw;
  }

  @media (max-width: 1300px) {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  @media (max-width: 1100px) {
    padding: 1rem 2rem;
  }

  @media (max-width: 800px) {
    padding: 0;
  }
`

const MenuButton = styled(Link)`
  outline: none;
  border: none;
  background-color: transparent;
  color: ${({ theme }) => theme.onBackgroundSemiLight};
  padding: 0.6rem 0 0.35rem;
  font-size: 1rem;

  transition:
    color 0.1s ease-in-out,
    border-bottom 0.1s ease-in-out;

  border-bottom: 2px solid transparent;

  :hover {
    color: ${({ theme }) => theme.primaryLight};
    border-bottom: 2px solid ${({ theme }) => theme.primaryLight};
  }
`

const ButtonSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  a:nth-of-type(1) {
    margin-left: 0;
  }

  a {
    margin: 0 1.5rem;
  }

  a:last-of-type {
    margin-right: 0;
  }

  @media (max-width: 800px) {
    display: none;
  }
`

const MobileMenu = styled('div', transientOptions)<{ $visible: boolean }>`
  z-index: 10;

  * {
    z-index: 11;
  }

  position: fixed;
  top: 0;
  left: 0;

  pointer-events: ${({ $visible }) => ($visible ? 'all' : 'none')};

  width: 100vw;

  transition:
    height 0.45s ease-in-out,
    opacity 0.45s ease-in-out;

  height: ${({ $visible }) => ($visible ? '100vh' : '0')};
  opacity: ${({ $visible }) => ($visible ? 1 : 0)};

  background-color: #fcfcfc;

  @media (min-width: 800px) {
    display: none;
  }
`

const MobileContent = styled.div`
  z-index: 11;

  margin-top: 3.5rem;
  padding: 0 3rem;

  font-weight: 600;

  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const MobileItem = styled(Link)<{ index: number; visible: boolean }>`
  width: 100%;

  color: ${({ theme }) => theme.onBackground};

  font-size: 1.75rem;

  transition:
    opacity 0.35s ease-in-out,
    transform 0.35s ease-in-out;
  transition-delay: ${({ index }) => index * 0.05}s;

  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transform: ${({ visible }) => (visible ? 'translateY(0)' : 'translateY(-0.45rem)')};
`
